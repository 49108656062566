/* eslint-disable no-mixed-spaces-and-tabs */


<template>
   


<div mdi-view-dashboard>
  

       <h2 class="primary--text  ma-6">Schiffsliste</h2>


<!-- <v-card class="ma-12 pa-3" v-if="eventbusmsg != 'nix'"  @click="eventbusmsg='nix'">
  EventBus Msg: {{eventbusmsg}}
</v-card> -->


<v-container class="mt-5 my1">

            <div class="mb-3">
                
                  <v-row class="text-center">
                    <v-col cols="12">

                          <v-btn small right class="mr-2 right"
                                        color="primary"
                                        @click=" userFilterKey = 'rbb', suche=''"
                                      >
                                        RBB
                                  </v-btn>

                                  <!-- <v-btn small right class="mr-2 right"
                                        color="primary"
                                        @click="userFilterKey = 'rib', suche=''"
                                      >
                                        RIB
                                  </v-btn> -->

                                  <v-btn small right class="mr-2 right"
                                        color="primary"
                                        @click="userFilterKey = 'srb', suche=''"
                                      >
                                        SRB
                                  </v-btn>

                                  <v-btn small right class="mr-2 right"
                                        color="primary"
                                        @click="userFilterKey = 'srk', suche=''"
                                      >
                                        SRK
                                  </v-btn>
                                 
                    </v-col>
                </v-row>

                <v-row class="text-center">
                    <v-col cols="12" class="mb-6 mt-0">
                            
                              <v-text-field
                                class="mb-4"
                                label="Schiffsuche"
                                v-model="suche"
                                hide-details="auto"
                              
                                prepend-inner-icon="mdi-text-search"
                                @click="userFilterKey = 'filter_nach_name'"
                              ></v-text-field>
                            
                    </v-col>
                </v-row>     

            </div>       
            
            <v-card outlined flat class="mt-2 mb-3 pa-5 gray--text" v-for=" schiff, id_sar in userFilter" :key="id_sar">
              <v-layout row wrap >

                <v-flex xs9 md8>

                  <v-img v-if = "schiff.mmsi !=='' && schiff.mmsi !=='0' && typeof(schiff.mmsi) !=='undefined' && schiff.mmsi !=='null'" class="ml-1 float-right mr-1  schatten  rounded-xl"  :src="getImgUrl(schiff.mmsi)"
                  max-height="100"
                  max-width="200"
                  ></v-img>
                  
                      <div class=" gray--text">{{schiff.schiff_name}}   ({{schiff.id_sar}})</div>
                      <div class="caption gray--text">{{schiff.modul_typ_bezeichnung}}</div>
                      <div  class="caption gray--text  mt-3">gebaut: {{schiff.schiff_werft}} Werft</div>
                      <div  class="caption gray--text  mt-1">Station: {{schiff.stationsname}} </div>
                </v-flex>
                
<!-- 
                <v-flex xs3 md4 v-if = " schiff.id_sar == selected_id_sar ">
                  xxxxx  93 xxx {{selected_id_sar}}
                </v-flex>
                 -->
                <!-- **********  wenn id_sar aus local storage mit aktueller sar_id aus Liste passt oder globale id_station = 1 (Zentrale), dann darf Pop-Ups öffnen-->

                <!-- UserID 50 = Michael Ulrichs  -->
                <v-flex xs3 md4  v-if = " schiff.id_sar == selected_id_sar || selected_id_station == 1 || user_id == 50 ">
                  
                  <div class="caption light-gray--text right ">
                    <v-icon @click=" gehezu_gruppenmodule(schiff.id_sar)"  class="grey--text mt-6  mr-1" >mdi-animation</v-icon>
                  </div>
                  <div class=" caption light-gray--text right">
                        <Popup @emit_closepopup_schiff="schiffpopupgeschlossen" :id_sar =schiff.id_sar  :stationsname =schiff.stationsname class="mt-6 mr-2"></Popup> 
                  </div>

                </v-flex>

              </v-layout>
              
            </v-card>

    </v-container>

</div>
</template>
<script>

import SchiffeService from '@/services/SchiffeService.js';
import Popup from '../components/Popup.vue';

import { EventBus } from '../main';

export default {
    data() {
        return {
            userFilterKey: "all",
            selected_id_sar: localStorage.id_sar_ausgewaehlt_global,
            selected_id_station: localStorage.id_station_ausgewaehlt_global,
            user_id: this.$store.state.user_id,
            username: "",
            password: "",
            schiffsliste: "",
            angemeldeter_user: "",
            msg: "",
            id:0,
            suche:'',
            eventbusmsg: 'nix',
            popupanzeigen: false
        };
    },
    computed: {

        //https://stackoverflow.com/questions/36797165/change-filter-on-click-event-vue-js
        userFilter() {
            return this[this.userFilterKey];
        },
        all() {
            return this.schiffsliste;
        },
        srb() {
            return this.schiffsliste.filter((schiff) => schiff.modul_typ_bezeichnung.substring(0, 3) == "SRB");
        },
        srk() {
            return this.schiffsliste.filter((schiff) => schiff.modul_typ_bezeichnung.substring(0, 3) == "SRK");
        },
        rib() {
            return this.schiffsliste.filter((schiff) => schiff.modul_typ_bezeichnung.substring(0, 3) == "RIB");
        },
        rbb() {
            return this.schiffsliste.filter((schiff) => schiff.modul_typ_bezeichnung.substring(0, 3) == "RBB");
        },
        filter_nach_name() {
          return this.schiffsliste.filter((schiff) => schiff.schiff_name.toLowerCase().indexOf(this.suche.toLowerCase()) > -1) 
        },
        
    },
    methods: {

      gehezu_gruppenmodule(id_sar) {  //*****  Michael */
       this.selected_id_sar= id_sar
          if(typeof(this.selected_id_sar) !== "undefined" && this.selected_id_sar !=='' ) { 
            localStorage.id_sar_ausgewaehlt_global = id_sar  } 
        this.sende_globale_id_sar(localStorage.id_sar_ausgewaehlt_global) 
        this.$router.push('/gruppenmodulliste')
        
      },



      sende_globale_id_sar() {  //*****  Michael */
      EventBus.$emit('sende_globale_id_sar',this.selected_id_sar)
      },
    


      async update_id_sar_global(selected_id_sar) {  //*****  Michael */
          //*** wenn Sar-Einheit in Dropdown ausgewählt wurde, dann diesen Wert in localStorage speichern //******  globale session variabel setzen  (siehe in App.vue  / mounted und watch ) */
        this.sende_globale_id_sar();
            if(typeof(this.selected_id_sar) !== "undefined" && this.selected_id_sar !=='' ) { localStorage.id_sar_ausgewaehlt_global = selected_id_sar  } 
        }
,

      schiffpopupgeschlossen() {
          this.zeige_schiffe();
      },

        //popupzeigen() {
         // this.$modal.show('example')
       // },

        async zeige_schiffe() {
         // alert('201');
            try {
                const credentials = {
                    username: this.username,
                    password: this.password
                };
                const response = await SchiffeService.zeige_alle_schiffe(credentials);
                
                if (typeof response.msg !=='undefined'){this.$router.push("/login")} //****  wenn keine Anmeldung */
               
                this.schiffsliste = response.recordset;
            }
            catch (error) {
               // this.msg = error.response.data.schiffsliste;
            }
        },
        zum_login() {
            this.$router.push("/login");
        },
        getImgUrl(mmsi) {
           return `https://photos.marinetraffic.com/ais/showphoto.aspx?size=thumb&mmsi=${mmsi}&imo=0`
        },

    },
    created() {
      
     // alert('230');
      // alert(this.$store.getters.isLoggedIn)
        if (!this.$store.getters.isLoggedIn) {
          this.$router.push('/login');
        }

        this.zeige_schiffe();
        
        EventBus.$on('testeventbus', eventbusparameter => {
          this.eventbusmsg =eventbusparameter
        })
      
    },
    components: { 
      Popup 
    }
};
</script>

<style>
.schatten{
  opacity:0.7;
	background: #F8F8F8; 
	border: solid #BDBDBD 1px; 
	box-shadow: 4px 5px 8px rgba(0, 0, 0, 0.2)  ; 
	-webkit-box-shadow: 4px 5px 8px rgba(0, 0, 0, 0.2)  ; 
	-moz-box-shadow: 4px 5px 8px rgba(0, 0, 0, 0.2)  ; 
}



.right {
 position:relative;
  float:right;
  margin-right:10%;
}
</style>