<template>
  <v-app id="inspire">
    
        <v-navigation-drawer v-model="drawer" app >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-h6 grey--text" style="color:#ba150f; font-weight:400; width:80% !important" >
                    Menü
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

          <v-divider></v-divider>

              <v-list dense nav>
                <v-list-item  v-for="item in items" :key="item.title" @click="$router.push({ name: item.name, path: item.to, params: {id:para }})" link router-link  class="SelectedTile"  active-class="linkActiveClass">
                  <v-list-item-icon>
                    <v-icon >{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}{{ item.id }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
        </v-navigation-drawer>
            <v-divider></v-divider>

        <v-app-bar height="80" app color="gray" prominent dark >
 <!-- src="../public/hintergrund.jpg" -->
 
            <template v-slot:img="{ props }">
              <!-- <v-img v-bind="props" gradient="to top right, rgba(84, 110, 122,.9), rgba(176, 190, 197, 1)" ></v-img> -->
              <!-- https://www.gradient-animator.com/ -->
              <v-img v-bind="props" class="hintergrund_gradient_animation" ></v-img>
            </template>

            <v-app-bar-nav-icon  @click="drawer = !drawer" style="color:white;" ></v-app-bar-nav-icon>

            <!-- <v-app-bar-title  class="pl-2 pb-6  " style="color:#ba150f; font-weight:400; width:80% !important" >Technik-Modul</v-app-bar-title> -->
            <v-app-bar-title  class="pl-2 pb-6  " style="color:white; font-weight:400; width:180px !important" >Technik-Modul</v-app-bar-title>

            <v-spacer></v-spacer>

            <!-- <v-app-bar-nav-icon class="mr-12" @click="parameter_aendern('login', 'login')">Param ändern</v-app-bar-nav-icon> -->

            <v-btn icon x-small class="ml-7 mr-8 mt-2" style="color:white;" @click="logout">
              <v-icon >mdi-logout</v-icon>
              Logout
            </v-btn>

            <img class="m-5 ml-auto mt-2 mr-2" position='right' :src="require('../public/logo.png')" height="60"/>
            
        </v-app-bar>

    <v-main>
      
      <keep-alive>
          <!-- <router-view v-on:check="drawer=!drawer,para='neuer Param' "  v-on:check1="drawer=!drawer,para='uuuuuuuuuuuu' ">www</router-view> -->
          <router-view ></router-view>
      </keep-alive>

    </v-main>


  </v-app>
  
</template>

<script>

 import { EventBus } from './main';


  export default {
    data: () => ({ 
    drawer: null ,
    para: ' Zeile 98 - paarmeeter von data',
   // eventbusparameter:'',

    
//***********************************  ICONS-Liste:   https://materialdesignicons.com/    https://pictogrammers.com/library/mdi/  ***********************/

      items: [
            { title: 'Login', name: 'login', icon: 'mdi-login' ,to:'/login',linkActiveClass: "active", linkExactActiveClass: "active"},
            // { title: 'Registrieren', name: 'sign-up', icon: 'mdi-account-plus'  , to:'/sign-up'},
            { title: 'Home', name: 'home', icon: 'mdi-home' , to:'/home' },
            { title: 'Schiffsliste', name: 'schiffsliste', icon: 'mdi-ferry',  to:'/schiffsliste' },
             { title: 'Gruppenmodulliste', name: 'gruppenmodulliste', icon: 'mdi-animation' , to:'/gruppenmodulliste' },
            { title: 'Einzelmodulliste', name: 'einzelmodulliste', icon: 'mdi-checkbox-multiple-blank' , to:'/einzelmodulliste' },

            // { title: 'Aufgabenliste', name: 'aufgabenliste', icon: 'mdi-book-open' , to:'/Aufgabenliste' },
            { title: 'Aufgabenliste', name: 'aufgabenliste_neu', icon: 'mdi-developer-board' , to:'/Aufgabenliste_neu' },
            { title: 'Werftliste', name: 'werftliste', icon: 'mdi-book-open' , to:'/Werftliste' },

            { title: 'Checkpunkte', name: 'checkpunkte', icon: 'mdi-checkbox-marked-outline' , to:'/Checkpunkte' },
            { title: 'Stationsverbräuche', name: 'stationsverbraeuche', icon: 'mdi-home-automation' ,  to:'/stationsverbraeuche' },

          ],
      right: null,
      id_sar_ausgewaehlt_global:'',
      name_sar_ausgewaehlt_global:''
      
    }),

    // created() {
    //     EventBus.$on('testeventbus', eventbusparameter => {
    //       console.log(eventbusparameter)
    //     })
    //       
    // },

    mounted() {
      
    if (localStorage.id_sar_ausgewaehlt_global) {
      this.id_sar_ausgewaehlt_global = localStorage.id_sar_ausgewaehlt_global;
    }

    if (localStorage.name_sar_ausgewaehlt_global) {
      this.name_sar_ausgewaehlt_global = localStorage.name_sar_ausgewaehlt_global;
    }


    
  },

  // watch: {
  //     id_sar_ausgewaehlt_global(newID_sar) {
  //     localStorage.id_sar_ausgewaehlt_global = newID_sar;
  //   }
  // },

    methods: {

      parameter_aendern(adresse, ziel) {
        this.para = this.para + " neu ";
        this.$router.push({ name: adresse, path: ziel, params: {id: this.para }})
        this.$emit('check', '')
        
      },
      logout() {
        localStorage.id_sar_ausgewaehlt_global = '';
        localStorage.id_station_ausgewaehlt_global = '';
        this.$forceUpdate();
       // window.location.reload();
       
//alert('147');
      this.$store.dispatch('logout');
      EventBus.$emit('emit_logout','von_logout')
     // this.$router.push('/login/:idlo');
      this.$router.push('/login');
    }
    },
   // emits: ['check'] ,
   

   
  }
</script>


<!-- login   home   ferry   animation  checkbox-multiple-blank   developer-board  book-open   checkbox-marked-outline   home-automation   -->

<style>

/* https://vuetifyjs.com/en/styles/colors/#material-colors */

i.v-icon.mdi-login { color: rgb(99, 99, 99) }
i.v-icon.mdi-home { color: rgb(72, 161, 37) }
i.v-icon.mdi-ferry { color: rgb(4, 176, 219) }
i.v-icon.mdi-animation { color: rgb(36, 133, 207) }
i.v-icon.mdi-checkbox-multiple-blank { color: rgb(9, 109, 185) }
i.v-icon.mdi-developer-board { color: purple }
i.v-icon.mdi-book-open { color: rgb(177, 96, 31) }
i.v-icon.mdi-checkbox-marked-outline { color: rgb(22, 153, 66) }
i.v-icon.mdi-home-automation { color: indigo }


h1 {color:#ba150f; font-weight:500}
h2 {color:#ba150f; font-weight:400}
main div {color:grey}
.linkActiveClass {color:red; }



.hintergrund_gradient_animation {
  /* background-image: url('../public/hintergrund_banner.jpg') no-repeat; */
     background: linear-gradient(192deg, #8e9291, #545a58, #949998, #6b7a77);
   
     /* background-color: #545a58;  */

    background-size: 800% 800%;

    -webkit-animation: AnimationName 20s ease infinite;
    -moz-animation: AnimationName 20s ease infinite;
    -o-animation: AnimationName 20s ease infinite;
    animation: AnimationName 20s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 59%}
    50%{background-position:100% 42%}
    100%{background-position:0% 59%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 59%}
    50%{background-position:100% 42%}
    100%{background-position:0% 59%}
}
@-o-keyframes AnimationName {
    0%{background-position:0% 59%}
    50%{background-position:100% 42%}
    100%{background-position:0% 59%}
}
@keyframes AnimationName {
    0%{background-position:0% 59%}
    50%{background-position:100% 42%}
    100%{background-position:0% 59%}
}




</style>